import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/workStyle.js";

const useStyles = makeStyles(styles);

export default function WorkSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem cs={12} sm={12} md={8}>
          <h2 className={classes.title}>Lots of thanks for your interest in the books of Duaa Rasho</h2>
          <h4 className={classes.description}>
            The mails will be all read and answered but sometimes it can take some time because I'm a very busy person.
            Here the mail if you want to copy it: doaa.rasho1@gmail.com
          </h4>
          <form>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}
